.container {
  align-items: center;
  background-color: var(--color-floatingElement);
  border-radius: 24px;
  box-shadow: var(--box-shadow-button);
  display: flex;
  flex-direction: column;
  max-width: 420px;
  padding: 48px;
}

.container:hover {
  cursor: pointer;
}

.description {
  margin-top: 10px;
}

.fileTypes {
  margin-top: 12px;
}

.title {
  margin-top: 24px;
}

@media only screen and (max-width: 640px) {
  .container {
    padding: 16px;
  }
}
