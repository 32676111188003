.content {
  column-gap: 32px;
  display: flex;
}

.divider {
  align-self: stretch;
  background-color: var(--color-white);
  width: 1px;
}

.lamportsAndLabel {
  display: flex;
  flex-direction: column;
  row-gap: 2px;
}