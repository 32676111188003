.container {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  column-gap: 24px;
}

.nftPhoto {
  border: 4px solid var(--color-menuPopup);
  box-sizing: border-box;
  filter: var(--drop-shadow-button);
  border-radius: 12px;
  margin-right: 14px;
}
