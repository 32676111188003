.headerBanner {
  background-color: var(--color-shader);
  height: 72px;
}

.childrenContainer {
  align-items: center;
  display: flex;
  height: 100%;
}
