.container {
  /* Should match coverPhoto height */
  height: 280px;
  position: relative;
}

.coverPhoto {
  background-color: var(--color-lightPurple);
  height: 280px;
  left: 0;
  object-fit: cover;
  position: absolute;
  top: 0;
  width: 100%;
}

.header {
  position: relative;
}

.headerGradient {
  background: linear-gradient(180deg, rgba(0, 0, 0, 0.24) 0%, rgba(255, 255, 255, 0) 100%);
  height: 128px;
  position: absolute;
  width: 100%;
}

/* Header breakpoint */
@media only screen and (max-width: 980px) {
  .container, .coverPhoto {
    height: 180px;
  }

  .headerGradient {
    height: 100px;
  }
}

@media only screen and (max-width: 640px) {
  .container, .coverPhoto {
    height: 120px;
  }
}