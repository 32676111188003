.asset {
  border-radius: 16px;
  height: 240px;
  object-fit: cover;
  width: 240px;
}

.assetContainer {
  position: relative;
}

.assets {
  column-gap: 12px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 12px;
}

.assetContainer:hover .imageOverlay {
  display: flex;
}

@media only screen and (max-width: 1024px) {
  .asset {
    height: 200px;
    width: 200px;
  }
}