.container {
  border: 1px solid var(--color-tertiary);
  border-radius: 16px;
  display: flex;
  padding: 16px;
}

.label {
  flex-grow: 1;
  text-align: left;
}

.noBorder {
  border: none;
  padding: 0px;
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 12px;
  }

  .noBorder {
    padding: 0px;
  }
}
