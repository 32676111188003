/* Should be consistent with getSelectCustomStyles */

.select {
  border: 1px solid var(--color-tertiary);
  border-radius: 16px;
  display: flex;
  height: 54px; 
  justify-content: space-between;
  padding: 16px;
  padding-right: 8px;
}

.select:hover {
  border: 1px solid var(--color-ghost);
  cursor: pointer;
}

.selectText {
  color: var(--color-primary);

  font-family: var(--font-family-body1);
  font-size: 18px;
  line-height: normal;
}