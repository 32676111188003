.body {
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  row-gap: 24px;
}

.sortButton {
  display: flex;
  justify-content: space-between;
}

.sortButtons {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}