.audienceSelectButton {
  border-radius: 16px;
  border: 1px solid var(--color-tertiary);
  display: flex;
  justify-content: space-between;
  padding: 16px 20px;
  width: 100%;
}

.audienceSelectButton:hover {
  cursor: pointer;
}

.popover {
  border-radius: 16px;
}