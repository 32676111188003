.container {
  align-items: center;
  background-color: var(--color-cardBackground);
  border-radius: 24px;
  box-shadow: var(--box-shadow-button);
  display: flex;
  justify-content: space-between;
  padding: 24px 36px;
}

.salesInfo {
  align-items: flex-end;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  row-gap: 4px;
  white-space: nowrap;
  width: 120px;
}

@media only screen and (max-width: 640px) {
  .salesInfo {
    width: auto;
  }
}

@media only screen and (max-width: 640px) {
  .container {
    column-gap: 8px;
    padding: 16px;
  }
}