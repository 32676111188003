.banner {
  position: fixed;
  width: 100%;
  z-index: var(--z-index-fixedBanner);
}

.body {
  flex-grow: 1;
}

.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
  justify-content: space-between;
}