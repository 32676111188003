.active {
  background: var(--color-purpleGradient);
  border-radius: 50%;
  height: 12px;
  margin-top: 12px;
  width: 12px;
}

.button {
  display: flex;
  filter: var(--drop-shadow-button);
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.colorCircle {
  border: 3px solid var(--color-white);
  border-radius: 50%;
  box-sizing: border-box;
  height: 56px;
  width: 56px;
}

.foreground {
  margin-left: -20px;
}

@media only screen and (max-width: 1024px) {
  .colorCircle {
    height: 52px;
    width: 52px;
  }
}