.blur {
  display: block;
  width: 100%;
}

.button {
  margin-top: 48px;
}

.container {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  position: relative;
}

.containerInner {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding-bottom: 82px;
  padding-top: 84px;
  z-index: 1;
}

.description {
  margin-top: 24px;
  max-width: 800px;
}

.gradient {
  left: 50%;
  position: absolute;
  transform: translateX(-50%);
  top: -200px;
  width: 1600px;
  z-index: 0;
}

@media only screen and (max-width: 768px) {
  .containerInner {
    padding-top: 64px;
  }
}

@media only screen and (max-width: 640px) {
  .gradient {
    width: 1100px;
  }
}
