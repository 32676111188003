.dnd {
  display: flex;
  width: 100%;
  overflow-x: auto;
  overflow-y: hidden;
  padding: 15px 0;
}

/* Dnd works better when you add margins to children elements rather than add gaps to the parent element's flex box */
.dnd > div {
  width: 25%;
  min-width: 150px;
  margin-right: 16px;
}

.img {
  width: 100%;
  height: 88px;
  object-fit: cover;
  border-radius: 8px;
}

.miniCard {
  border: 1px solid var(--color-tertiary);
  border-radius: 8px;
  box-sizing: border-box;
  height: 88px;
  position: relative;
  width: 100%;
  cursor: pointer;
}

.miniImageCard {
  width: 100%;
  overflow: none;
}

.miniImageCard {
  width: 100%;
}

.miniImageCard:focus .removeIconContainer,
.miniImageCard:hover .removeIconContainer {
  display: block;
}

.removeIconContainer {
  cursor: pointer;
  position: absolute;
  width: fit-content;
  height: fit-content;
  top: -12px;
  right: -12px;
  z-index: 1;
  display: none;
}

.uploadCard {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
