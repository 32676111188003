.body {
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 40px;
}

.image {
  max-width: 700px;
}


@media only screen and (max-width: 640px) {
  .image {
    max-width: 100%;
  }
}