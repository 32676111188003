.body {
  display: flex;
  flex-direction: column;
}

.content {
  margin-top: 48px;
}

.prescreenInfo {
  align-items: center;
  background: var(--color-lightPurpleGradient);
  border-radius: 24px;
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  padding: 48px;
}

.prescreenInfoDescription {
  margin-top: 16px;
  max-width: 900px;
}