.artistPillButton {
  position: absolute;
  left: 28px;
  top: 28px;
  z-index: 1;
}

.bottomSection {
  flex-grow: 1;
  padding: 12px;
  padding-top: 0px;
}

.container {
  background-color: var(--color-cardBackground);
  border-radius: 24px;
  padding: 12px;
  position: relative;
  width: 100%;
  height: 100%;
}

.containerShadow {
  box-shadow: var(--box-shadow-button);
}

.nftAssets {
  column-gap: 12px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.progressContainer {
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  justify-content: flex-end;
}