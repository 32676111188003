.grid {
  column-gap: 36px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  row-gap: 36px;
}

@media only screen and (max-width: 1024px) {
  .grid {
    grid-template-columns: minmax(0, 1fr);
  }
}

@media only screen and (max-width: 768px) {
  .grid {
    row-gap: 28px;
  }
}
