.container {
  background-color: var(--color-cardBackground);
  display: flex;
  align-items: center;
  padding: 16px 24px;
  column-gap: 24px;
  box-shadow: var(--box-shadow-button);
  border-radius: 16px;
}

.right {
  display: flex;
  flex-direction: column;
  justify-content: center;
}