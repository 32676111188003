.button {
  align-items: center;
  background-color: var(--color-floatingElement) !important;
  border-radius: 24px;
  box-shadow: var(--box-shadow-button);
  column-gap: 8px;
  display: inline-flex;
  padding: 8px 16px 8px 10px;
}

.buttonShadow {
  background-color: var(--color-artistPillShadow) !important;
}

.image {
  align-items: center;
  background-color: var(--color-lightPurple);
  border-radius: 50%;
  box-sizing: content-box;
  display: flex;
  height: 32px;
  justify-content: center;
  object-fit: cover;
  width: 32px;
}

.imageCollab {
  margin-left: -20px;
}

.truncate {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
