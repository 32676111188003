.button {
  left: 50%;
  position: absolute;
  top: 48px;
  transform: translateX(-50%);
  width: 240px;
  z-index: 1;
}

.container {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
}