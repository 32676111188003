.container {
  column-gap: 16px;
  display: flex;
  align-items: center;
}

.textInput {
  height: 54px;
  width: 100%;
}
