.container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.description {
  margin-top: 24px;
  max-width: 906px;  
}
