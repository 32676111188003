.body {
  align-items: center;
  display: flex;
  flex-direction: column;
  padding: 80px 0;
  row-gap: 80px;
}

.header {
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.heroContainer {
  display: block;
  width: 100%;
}

.section {
  align-items: center;
  display: flex;
  width: 100%;
  flex-direction: column;
  row-gap: 36px;
}

.grid {
  width: 100%;
}

@media only screen and (max-width: 1280px) {
  .body {
    padding: 64px 0;
    row-gap: 64px;
  }
}

@media only screen and (max-width: 1024px) {
  .body {
    padding: 48px 0;
    row-gap: 48px;
  }
}

@media only screen and (max-width: 768px) {
  .body {
    padding: 32px 0;
    row-gap: 32px;
  }
}