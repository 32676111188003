.button {
  align-items: center;
  column-gap: 12px;
  display: flex;
  padding: 8px 24px;
}

@media only screen and (max-width: 640px) {
  .button {
    padding: 8px 16px;
  }
}