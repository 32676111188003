.container {
  display: flex;
  justify-content: center;
  position: relative;
  width: 100%;
}

.historyButton {
  align-items: center;
  background-color: var(--color-overlayButton) !important;
  border-radius: 100px;
  display: flex;
  height: 48px;
  justify-content: center;
  padding: 0px 20px;
  position: absolute;
  left: 32px;
  top: 32px;
  z-index: 1;
}

.removeButton {
  align-items: center;
  background-color: var(--color-overlayButton) !important;
  border-radius: 50%;
  display: flex;
  height: 48px;
  justify-content: center;
  position: absolute;
  right: 32px;
  top: 32px;
  width: 48px;
  z-index: 1;
}

@media only screen and (max-width: 768px) {
  .status {
    max-width: 280px;
  }
}