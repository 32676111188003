.communityHoldersContainer {
  flex-shrink: 0;
  overflow-y: scroll;
}

.goToDashboardCta {
  flex-shrink: 0;
}

.goToDashboardPill {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 36px 48px;
  box-shadow: var(--box-shadow-button);
  border-radius: 24px;
  column-gap: 32px;
}

@media only screen and (max-width: 640px) {
  .goToDashboardPill {
    align-items: center;
    flex-direction: column;
    row-gap: 24px;
    text-align: center;
  }
}