.backButton {
  align-self: normal;
}

.body {
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.bodyText {
  width: 100%;
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 12px;
}

.description {
  max-width: 800px;
}

.input {
  width: 500px;
}

.inviteButton {
  margin-top: 24px;
  width: 254px;
}

.title {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  row-gap: 8px;
}

@media only screen and (max-width: 768px) {
  .input {
    width: 400px;
  }
}