@keyframes fadein {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}


.body {
  opacity: 0;
  animation-delay: 0s;
  animation-duration: 2.0s;
  animation-name: fadein;
  animation-fill-mode: forwards;
}