.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 700px;
  width: 100%;
}

.deleteButton {
  margin-top: 32px;
}

.form {
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 640px;
  row-gap: 32px;
}

.fundingTierFormContainer {
  margin-top: 32px;
}

.inputWrapper {
  width: 100%;
}

.saveButton {
  width: fit-content;
  margin: 0 auto;
}
