.table {
  align-items: center;
  display: grid;
  height: 64px;
  column-gap: 48px;
  grid-template-columns: minmax(110px, 1.5fr) minmax(0, 2fr) minmax(100px, 2fr) 24px;
  width: 100%;
}

@media only screen and (max-width: 1536px) {
  .table {
    column-gap: 32px;
  }
}

@media only screen and (max-width: 1280px) {
  .table {
    column-gap: 24px;
  }
}

@media only screen and (max-width: 640px) {
  .table {
    height: 41px;
    grid-template-columns: minmax(60px, 1fr) repeat(2, minmax(0, 2fr)) minmax(0, .5fr);
  }
}
