.bidLine {
  align-items: center;
  display: flex;
}

.container {
  align-items: center;
  background-color: var(--color-floatingElement);
  border-radius: 16px;
  box-shadow: var(--box-shadow-button);
  column-gap: 40px;
  display: flex;
  justify-content: space-between;
  padding: 16px 24px;
}

.image {
  border-radius: 50%;
  height: 32px;
  margin-left: 12px;
  margin-right: 8px;
  width: 32px;
}

.left {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.right {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

@media only screen and (max-width: 640px) {
  .container {
    column-gap: 20px;
    padding: 12px 16px;
  }

  .right {
    row-gap: 4px;
  }
}