.refundsBody {
  margin-top: 64px;
}

@media only screen and (max-width: 1024px) {
  .refundsBody {
    margin-top: 48px;
  }
}

@media only screen and (max-width: 768px) {
  .refundsBody {
    margin-top: 32px;
  }
}