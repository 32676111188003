.grow {
  flex-grow: 1;
}

.label {
  margin-left: 16px;
}

.tab {
  border-bottom: 3px solid transparent;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 13px;
  padding-left: 16px;
  padding-right: 16px;
  row-gap: 4px;
  text-align: center;
}

.tabActive {
  border-bottom-color: var(--color-brightPurple);
}

@media only screen and (max-width: 640px) {
  .label {
    margin-left: 8px;
  }

  .tab {
    padding-left: 12px;
    padding-right: 12px;
  }
}

@media only screen and (max-width: 500px) {
  .tab {
    padding-left: 8px;
    padding-right: 8px;
  }
}