.container {
  column-gap: 32px;
  display: flex;
}

.divider {
  border-left: 1px solid var(--color-tertiary);
}

.section {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  row-gap: 2px;
}

@media only screen and (max-width: 640px) {
  .container {
    column-gap: 24px;
  }
}