.container {
  align-items: center;
  column-gap: 48px;
  display: flex;
  position: relative;
}

.line {
  border-bottom: 1px dashed var(--color-ghost);
  bottom: 6px;
  left: 70px;
  position: absolute;
  width: 368px;
}