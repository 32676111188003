.body {
  display: flex;
  flex-direction: column;
}

.content {
  margin-top: 48px;
}

.description {
  align-self: center;
  margin-top: 24px;
  max-width: 900px;
}

.header {
  align-items: center;
  display: flex;
  justify-content: space-between;
}

.shadowButton {
  column-gap: 8px;
  padding: 12px 16px;
}


/* Header breakpoint */
@media only screen and (max-width: 980px) {
  .header {
    justify-content: center;
  }
}