.button {
  width: 230px;
}

.buttons {
  column-gap: 32px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  row-gap: 24px;
}