.container {
  border-radius: 16px;
  border: 1px solid var(--color-tertiary);
  display: flex;
  height: auto;
  overflow: hidden;
}

.containerError {
  border: 1px solid var(--color-error);
}

.content {
  align-items: center;
  column-gap: 12px;
  display: flex;
  max-height: 400px;
  min-height: 68px;
  overflow-x: auto;
  overflow-y: auto;
  padding: 12px 16px;
  width: 100%;
}

.input {
  background-color: transparent;
  border-style: none;
  flex-grow: 1;
  height: 40px;
  outline: none;
  width: 100%;
}

.input::placeholder {
  color: var(--color-tertiary);
}

.wrap {
  flex-wrap: wrap;
  row-gap: 8px;
}
