.container {
  border-top: 1px solid var(--color-tertiary);
  padding: 67px 0;
}

.content {
  display: flex;
  flex-direction: column;
  justify-content: center;
  row-gap: 16px;
}

.imageContainer {
  background-color: var(--color-floatingElement);
  box-shadow: var(--box-shadow-button);
  padding: 8px;
  height: 250px;
  width: 250px;
}

.innerContainer {
  column-gap: 48px;
  display: flex;
  flex-direction: row;
}

@media only screen and (max-width: 980px) {
  .innerContainer {
    align-items: center;
    flex-direction: column;
    row-gap: 48px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    padding: 40px 16px;
  }
}

@media only screen and (max-width: 640px) {
  .container {
    padding: 32px 16px;
  }

  .innerContainer {
    row-gap: 24px;
  }
}
