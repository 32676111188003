.container {
  display: flex;
  flex-direction: column;
  row-gap: 48px;
}

@media only screen and (max-width: 768px) {
  .container {
    row-gap: 32px;
  }
}

@media only screen and (max-width: 640px) {
  .container {
    row-gap: 24px;
  }
}