.children {
  margin-top: 48px;
  width: 100%;
}

.container {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.mobileTitleAndNav {
  align-self: stretch;
}

.progressBar {
  margin-top: 48px;
}

.title {
  margin: auto;
}

.titleRow {
  align-self: stretch;
  display: flex;
  justify-content: space-between;
}

/* Header breakpoint */
@media only screen and (max-width: 980px) {
  .children {
    margin-top: 32px;
  }
}