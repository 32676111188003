.infoLeft {
  padding-bottom: 4px;
}

.numPieces {
  width: 120px;
}

.seriesName {
  margin-top: 10px;
  width: 250px;
}
