.container {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin: auto;
  max-width: 820px;
  row-gap: 48px;
}

.infoSection {
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

/* Header breakpoint */
@media only screen and (max-width: 980px) {
  .container {
    row-gap: 32px;
  }
}