.assetAndTitle {
  align-items: center;
  column-gap: 48px;
  display: flex;
}

.image {
  border-radius: 16px;
  height: 120px;
  object-fit: cover;
  width: 120px;
}

.refundButton {
  width: 240px;
}

.row {
  align-items: center;
  border-radius: 24px;
  box-shadow: var(--box-shadow-button);
  column-gap: 80px;
  display: flex;
  justify-content: space-between;
  padding: 24px;
}

@media only screen and (max-width: 1024px) {
  .assetAndTitle {
    column-gap: 32px;
  }

  .row {
    column-gap: 100px;
  }
}

@media only screen and (max-width: 768px) {
  .image {
    height: 60px;
    width: 60px;
  }

  .refundButton {
    width: 180px;
  }

  .row {
    column-gap: 32px;
  }
}

@media only screen and (max-width: 640px) {
  .assetAndTitle {
    column-gap: 16px;
  }

  .row {
    flex-direction: column;
    row-gap: 24px;
  }
}