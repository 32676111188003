.asset {
  flex-shrink: 0;
  min-height: 245px;
  min-width: 435px;
}

.info {
  align-items: flex-start;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.text {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

@media only screen and (max-width: 1024px) {
  .asset {
    min-height: 0;
    min-width: 0;
  }
}