.buttons {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 36px;
  row-gap: 32px;
}

.mediaUpload {
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 64px;
}

.subtitle {
  text-align: center;
}
