.container {
  margin-left: 24px;
  margin-right: 24px;
}

.labelAndValue {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.priceAndDate {
  column-gap: 32px;
  display: flex;
  margin-bottom: 24px;
  margin-top: 20px;
}

.separator {
  background-color: var(--color-tertiary);
  height: 1px;
  margin-top: 20px;
  width: 100%;
}