.container {
  background: var(--color-lightPurpleGradient);
  padding: 48px 0 60px 0;
  min-height: 700px;
}

.header {
  display: flex;
  align-items: center;
  column-gap: 24px;
}

.headerContainer {
  display: flex;
  flex-direction: column;
  row-gap: 24px;
  margin-bottom: 40px;
}

.headerDivider {
  border: 1px solid var(--color-darkPurple);
}

.photo {
  height: 72px;
  width: 72px;
  border: 4px solid var(--color-floatingElement);
  box-sizing: border-box;
  /* Button shadow */
  filter: drop-shadow(var(--box-shadow-button));
  border-radius: 12px;
  object-fit: cover;
}