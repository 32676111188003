.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dndContainer {
  margin-top: 32px;
  width: 100%;
  display: flex;
  flex-direction: column;
}

.saveButton {
  margin: 48px auto 0;
  width: fit-content;
}
