.container {
  padding: 12px 24px;
  border-radius: 100px;
  position: relative;
  box-sizing: border-box;
  overflow: hidden;
}

.purpleBorder {
  border: 1px solid var(--color-brightPurple);
}

.purpleDarkModeBorder {
  border: 1px solid var(--color-brightPurple-darkMode);
}

.greyBorder {
  border: 1px solid var(--color-primary);
}

.greyDarkModeBorder {
  border: 1px solid var(--color-primary-darkMode);
}

.votePercentage {
  flex-shrink: 0;
  width: 54px;
}

.progressBar {
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 12%;
  box-sizing: border-box;
  transition: var(--transition-all-smoothly);
}
