.body {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.connectWalletButton {
  margin-top: 32px;
}

.image {
  max-width: 80%;
  /* Reduces layout shift */
  min-height: 300px;
  width: 600px;
}

.title {
  margin-top: 48px;
}

@media only screen and (max-width: 1800px) {
  .image {
    width: 500px;
  }
}

@media only screen and (max-width: 768px) {
  .image {
    min-height: 180px;
  }
}