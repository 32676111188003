/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

.backgroundColorAliceBlue {
  background-color: var(--color-aliceBlue);
}

.backgroundColorAntiFlashWhite {
  background-color: var(--color-antiFlashWhite);
}

.backgroundColorArtistPillShadow {
  background-color: var(--color-artistPillShadow);
}

.backgroundColorBackgroundOverlay {
  background-color: var(--color-backgroundOverlay);
}

.backgroundColorBackgroundOverlayDark {
  background-color: var(--color-backgroundOverlayDark);
}

.backgroundColorBlueGradient {
  background: var(--color-blueGradient);
}

.backgroundColorBrightGray {
  background-color: var(--color-brightGray);
}

.backgroundColorBrightPurple {
  background-color: var(--color-brightPurple);
}

.backgroundColorCadmiumGreen {
  background-color: var(--color-cadmiumGreen);
}

.backgroundColorCardBackground {
  background-color: var(--color-cardBackground);
}

.backgroundColorClickableAreaBackground {
  background-color: var(--color-clickableAreaBackground);
}

.backgroundColorCultured {
  background-color: var(--color-cultured);
}

.backgroundColorDarkCerulean {
  background-color: var(--color-darkCerulean);
}

.backgroundColorDarkGunmetal {
  background-color: var(--color-darkGunmetal);
}

.backgroundColorDarkPurple {
  background-color: var(--color-darkPurple);
}

.backgroundColorDimmer {
  background-color: var(--color-dimmer);
}

.backgroundColorError {
  background-color: var(--color-error);
}

.backgroundColorFloatingElement {
  background-color: var(--color-floatingElement);
}

.backgroundColorFooter {
  background: var(--color-footer);
}

.backgroundColorGhost {
  background-color: var(--color-ghost);
}

.backgroundColorGreen {
  background-color: var(--color-green);
}

.backgroundColorGreenGradient {
  background: var(--color-greenGradient);
}

.backgroundColorGreenishGray {
  background-color: var(--color-greenishGray);
}

.backgroundColorLightBlue {
  background-color: var(--color-lightBlue);
}

.backgroundColorLightPink {
  background-color: var(--color-lightPink);
}

.backgroundColorLightPurple {
  background-color: var(--color-lightPurple);
}

.backgroundColorLightPurpleGradient {
  background: var(--color-lightPurpleGradient);
}

.backgroundColorLightPurpleHover {
  background-color: var(--color-lightPurpleHover);
}

.backgroundColorLightPurpleVerticalGradient {
  background: var(--color-lightPurpleVerticalGradient);
}

.backgroundColorLinkWater {
  background-color: var(--color-linkWater);
}

.backgroundColorMaximumRed {
  background-color: var(--color-maximumRed);
}

.backgroundColorMediumBlue {
  background-color: var(--color-mediumBlue);
}

.backgroundColorMenuPopup {
  background-color: var(--color-menuPopup);
}

.backgroundColorMidnightBlue {
  background-color: var(--color-midnightBlue);
}

.backgroundColorNavy {
  background-color: var(--color-navy);
}

.backgroundColorNavyHover {
  background-color: var(--color-navyHover);
}

.backgroundColorOverlayButton {
  background-color: var(--color-overlayButton);
}

.backgroundColorPhantomPurple {
  background-color: var(--color-phantomPurple);
}

.backgroundColorPink {
  background-color: var(--color-pink);
}

.backgroundColorPinkVerticalGradient {
  background: var(--color-pinkVerticalGradient);
}

.backgroundColorPopheadzDarkGray {
  background-color: var(--color-popheadzDarkGray);
}

.backgroundColorPopheadzLightGray {
  background-color: var(--color-popheadzLightGray);
}

.backgroundColorPrimary {
  background-color: var(--color-primary);
}

.backgroundColorProgressBar {
  background-color: var(--color-progressBar);
}

.backgroundColorPurple {
  background-color: var(--color-purple);
}

.backgroundColorPurpleGradient {
  background: var(--color-purpleGradient);
}

.backgroundColorPurpleGradientForText {
  background: var(--color-purpleGradientForText);
}

.backgroundColorPurpleGradientHover {
  background-color: var(--color-purpleGradientHover);
}

.backgroundColorRed {
  background-color: var(--color-red);
}

.backgroundColorRedGradient {
  background: var(--color-redGradient);
}

.backgroundColorRedHover {
  background-color: var(--color-redHover);
}

.backgroundColorScheme11 {
  background-color: var(--color-scheme11);
}

.backgroundColorScheme12 {
  background-color: var(--color-scheme12);
}

.backgroundColorScheme21 {
  background-color: var(--color-scheme21);
}

.backgroundColorScheme22 {
  background-color: var(--color-scheme22);
}

.backgroundColorScheme31 {
  background-color: var(--color-scheme31);
}

.backgroundColorScheme32 {
  background-color: var(--color-scheme32);
}

.backgroundColorScheme41 {
  background-color: var(--color-scheme41);
}

.backgroundColorScheme42 {
  background-color: var(--color-scheme42);
}

.backgroundColorScheme51 {
  background-color: var(--color-scheme51);
}

.backgroundColorScheme52 {
  background-color: var(--color-scheme52);
}

.backgroundColorScheme61 {
  background-color: var(--color-scheme61);
}

.backgroundColorScheme62 {
  background-color: var(--color-scheme62);
}

.backgroundColorSeashell {
  background-color: var(--color-seashell);
}

.backgroundColorSecondary {
  background-color: var(--color-secondary);
}

.backgroundColorShader {
  background-color: var(--color-shader);
}

.backgroundColorSinopia {
  background-color: var(--color-sinopia);
}

.backgroundColorSkeletonBase {
  background-color: var(--color-skeletonBase);
}

.backgroundColorSkeletonHighlight {
  background-color: var(--color-skeletonHighlight);
}

.backgroundColorSpotlightOverlay {
  background-color: var(--color-spotlightOverlay);
}

.backgroundColorTertiary {
  background-color: var(--color-tertiary);
}

.backgroundColorTransparent {
  background-color: var(--color-transparent);
}

.backgroundColorWebsiteBackground {
  background-color: var(--color-websiteBackground);
}

.backgroundColorWhite {
  background-color: var(--color-white);
}

.backgroundColorYellow {
  background-color: var(--color-yellow);
}

