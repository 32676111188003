.campaignProgress {
  position: relative;
}

.coloredContainer {
  width: 100%;
  height: 100%;
}

.container {
  display: flex;
  flex-direction: column;
  margin: auto;
  width: 100%;
  align-items: center;
}

.cta {
  margin-top: 60px;
  width: 100%;
}

.editAboutButton {
  width: 180px;
  margin: 0 auto;
}

.editBasicInfoButton {
  width: 220px;
}

.fundingTiers {
  align-self: stretch;
  margin-top: 32px;
}

.progressEmoji {
  position: absolute;
  left: 0;
  top: 0;
}

.subtitle {
  margin-top: 12px;
}

.titleAndTagline {
  margin-top: 56px;
}

.usernameButton {
  height: 48px;
  border-radius: 24px;
  box-shadow: var(--box-shadow-button);
}

.whiteBar {
  width: 100%;
  border: 2px solid var(--color-white);
  margin-top: 12px;
}
