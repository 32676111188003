/**
 * THIS FILE IS AUTOMATICALLY GENERATED BY genColors.js.
 *
 * DO NOT MANUALLY MODIFY THIS FILE.
 */

.colorArtistPillShadow {
  color: var(--color-artistPillShadow);
}

.colorBackgroundOverlay {
  color: var(--color-backgroundOverlay);
}

.colorBackgroundOverlayDark {
  color: var(--color-backgroundOverlayDark);
}

.colorBrightPurple {
  color: var(--color-brightPurple);
}

.colorCardBackground {
  color: var(--color-cardBackground);
}

.colorClickableAreaBackground {
  color: var(--color-clickableAreaBackground);
}

.colorDarkCerulean {
  color: var(--color-darkCerulean);
}

.colorDarkPurple {
  color: var(--color-darkPurple);
}

.colorDimmer {
  color: var(--color-dimmer);
}

.colorError {
  color: var(--color-error);
}

.colorFloatingElement {
  color: var(--color-floatingElement);
}

.colorGreen {
  color: var(--color-green);
}

.colorGhost {
  color: var(--color-ghost);
}

.colorLightBlue {
  color: var(--color-lightBlue);
}

.colorLightPink {
  color: var(--color-lightPink);
}

.colorLightPurple {
  color: var(--color-lightPurple);
}

.colorLightPurpleHover {
  color: var(--color-lightPurpleHover);
}

.colorLinkWater {
  color: var(--color-linkWater);
}

.colorMenuPopup {
  color: var(--color-menuPopup);
}

.colorNavy {
  color: var(--color-navy);
}

.colorNavyHover {
  color: var(--color-navyHover);
}

.colorOverlayButton {
  color: var(--color-overlayButton);
}

.colorPink {
  color: var(--color-pink);
}

.colorPrimary {
  color: var(--color-primary);
}

.colorProgressBar {
  color: var(--color-progressBar);
}

.colorPurple {
  color: var(--color-purple);
}

.colorPurpleGradientHover {
  color: var(--color-purpleGradientHover);
}

.colorRed {
  color: var(--color-red);
}

.colorRedHover {
  color: var(--color-redHover);
}

.colorSecondary {
  color: var(--color-secondary);
}

.colorShader {
  color: var(--color-shader);
}

.colorSkeletonBase {
  color: var(--color-skeletonBase);
}

.colorSkeletonHighlight {
  color: var(--color-skeletonHighlight);
}

.colorSpotlightOverlay {
  color: var(--color-spotlightOverlay);
}

.colorTransparent {
  color: var(--color-transparent);
}

.colorTertiary {
  color: var(--color-tertiary);
}

.colorWebsiteBackground {
  color: var(--color-websiteBackground);
}

.colorWhite {
  color: var(--color-white);
}

.colorYellow {
  color: var(--color-yellow);
}

.colorPhantomPurple {
  color: var(--color-phantomPurple);
}

.colorScheme11 {
  color: var(--color-scheme11);
}

.colorScheme12 {
  color: var(--color-scheme12);
}

.colorScheme21 {
  color: var(--color-scheme21);
}

.colorScheme22 {
  color: var(--color-scheme22);
}

.colorScheme31 {
  color: var(--color-scheme31);
}

.colorScheme32 {
  color: var(--color-scheme32);
}

.colorScheme41 {
  color: var(--color-scheme41);
}

.colorScheme42 {
  color: var(--color-scheme42);
}

.colorScheme51 {
  color: var(--color-scheme51);
}

.colorScheme52 {
  color: var(--color-scheme52);
}

.colorScheme61 {
  color: var(--color-scheme61);
}

.colorScheme62 {
  color: var(--color-scheme62);
}

.colorBrightGray {
  color: var(--color-brightGray);
}

.colorMediumBlue {
  color: var(--color-mediumBlue);
}

.colorAliceBlue {
  color: var(--color-aliceBlue);
}

.colorSinopia {
  color: var(--color-sinopia);
}

.colorCultured {
  color: var(--color-cultured);
}

.colorCadmiumGreen {
  color: var(--color-cadmiumGreen);
}

.colorSeashell {
  color: var(--color-seashell);
}

.colorMaximumRed {
  color: var(--color-maximumRed);
}

.colorGreenishGray {
  color: var(--color-greenishGray);
}

.colorMidnightBlue {
  color: var(--color-midnightBlue);
}

.colorAntiFlashWhite {
  color: var(--color-antiFlashWhite);
}

.colorDarkGunmetal {
  color: var(--color-darkGunmetal);
}

.colorPopheadzDarkGray {
  color: var(--color-popheadzDarkGray);
}

.colorPopheadzLightGray {
  color: var(--color-popheadzLightGray);
}

