.modal {
  display: flex;
  flex-direction: column;
  margin: auto;
  min-height: 400px;
  max-width: 890px;
}

.modalHeader {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-top: -32px;
  row-gap: 16px;
}

.saveButtonContainer {
  display: flex;
  margin: auto;
}

.saveButton {
  align-self: center;
  width: 212px;
}

.search {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.maxActionsPopover {
  text-align: center;
  max-width: 336px;
}

@media only screen and (max-width: 980px) {
  .modalHeader {
    margin-top: 0;
    margin-bottom: 16px;
  }

  .saveButtonDivider {
    border-top: 1px solid var(--color-tertiary);
    margin-left: -48px;
    width: calc(100% + 96px);
    margin-bottom: 16px;
  }

  .saveButtonContainer {
    display: unset;
    margin: unset;
    background-color: var(--color-white);
    position: absolute;
    left: 0;
    bottom: 0;
    width: 100%;
    padding: 0 48px 38px 48px;
  }

  .saveButton {
    width: 100%;
    height: 54px;
  }
}

@media only screen and (max-width: 768px) {
  .saveButtonContainer {
    padding: 0 16px 38px 16px;
  }

  .saveButtonDivider {
    margin-left: -16px;
    width: calc(100% + 32px);
  }
}
