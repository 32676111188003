.container {
  position: relative;
}

.container:hover .overlay {
  display: flex;
}

.dropShadow {
  filter: var(--drop-shadow-button);
}

.fullHeightAndWidthContainer {
  width: 100%;
  height: 100%;
}
