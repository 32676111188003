.clearImageButton {
  align-self: flex-start;
}

.container {
  border-radius: 16px;
  position: relative;
}

.container:hover {
  cursor: pointer;
}

.containerBorder {
  border: 1px solid var(--color-tertiary);
}

.description {
  margin-top: 9px;
}

.photo {
  height: 100%;
  object-fit: cover;
  width: 100%;
  border-radius: 16px;
}

.supportedFileType {
  margin-top: 14px;
  text-transform: uppercase;
}

.title {
  margin-top: 24px;
}

.uploadContainer {
  padding: 64px 60px;
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
}

.removeIconContainer {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 1;
}