.ant-notification, .ant-message, .ant-popover {
  z-index: var(--z-index-antdNotification);
}

.ant-notification-notice {
  width: auto;
}

.ant-notification-notice {
  background-color: var(--color-floatingElement);
}

.ant-notification-notice-message {
  color: var(--color-primary);
}

.ant-popover-arrow {
  display: none;
}

.ant-popover-inner {
  background-color: var(--color-menuPopup);
  border-radius: 8px;
  box-shadow: var(--box-shadow-button);
  overflow: hidden;
}

.ant-popover-inner-content {
  max-height: 90vh;
  overflow-y: auto;
  padding: 24px;
}

.ant-popover-placement-bottom {
  padding-top: 12px;
}

.ant-tooltip {
  max-width: none;
}

.ant-tooltip-inner {
  border-radius: 16px;
  padding: 12px 16px;
}