.assetContainer {
  max-height: 800px;
  width: 100%;
}

.container {
  max-width: 792px;
  width: 100%;
  padding: 36px 48px;
  background-color: var(--color-cardBackground);
  border-radius: 24px;
  box-shadow: var(--box-shadow-button);
}