.attribute {
  margin: 8px 0;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  row-gap: 2px;
}

.traitValue {
  margin-left: 6px;
}
