.container {
  column-gap: 100px;
  display: flex;
}

@media only screen and (max-width: 1280px) {
  .container {
    flex-direction: column;
    row-gap: 32px;
    max-width: 792px;
    margin: auto;
  }
}