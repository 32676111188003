/*
 * Slider
 */
.slider {
  border: 1px solid var(--color-tertiary);
  border-radius: 34px;
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: var(--color-white);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.slider:before {
  border-radius: 50%;
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 4px;
  bottom: 3px;
  background-color: var(--color-ghost);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

/* The switch - the box around the slider */
.switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 24px;
}

/* Hide default HTML checkbox */
.switchInput {
  opacity: 0;
  width: 0;
  height: 0;
}

.switchInput:checked + .slider {
  background: var(--color-purpleGradient);
}

.switchInput:checked + .slider:before {
  -webkit-transform: translateX(14px);
  -ms-transform: translateX(14px);
  transform: translateX(14px);
}

.switchInput:checked + .slider:before {
  background-color: var(--color-white);
}
