.container {
  align-items: center;
  border-radius: 16px;
  border: solid 1px var(--color-tertiary);
  display: flex;
  height: 58px;
  justify-content: space-between;
  padding: 0 8px;
  width: 140px;
}

.skeleton {
  height: 20px;
  width: 48px;
}