.container {
  background-color: var(--color-cardBackground);
  border-radius: 24px;
  box-shadow: var(--box-shadow-button);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  row-gap: 16px;
  width: 100%;
  height: 100%;
}

.loadingSpinner {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}

.nftsSection {
  column-gap: 12px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.profilePhoto {
  background-color: var(--color-lightPurple);
  border: 4px solid var(--color-cardBackground);
  border-radius: 50%;
  box-shadow: var(--box-shadow-button);
  flex-shrink: 0;
  height: 100px;
  object-fit: cover;
  width: 100px;
}

.userSection {
  align-items: center;
  column-gap: 24px;
  display: flex;
  padding: 26px 24px;
}

.usernameAndBio {
  display: flex;
  flex-direction: column;
  row-gap: 4px;
  word-break: break-word;
}

@media only screen and (max-width: 1280px) {
  .userSection {
    padding-bottom: 16px;
    padding-top: 16px;
  }
}

@media only screen and (max-width: 768px) {
  .userSection {
    padding-bottom: 8px;
    padding-left: 0px;
    padding-right: 0px;
    padding-top: 8px;
  }
}
