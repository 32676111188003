.artistPillContainer {
  column-gap: 24px;
  display: flex;
  margin-bottom: 16px;
}

.body {
  display: flex;
  flex-direction: column;
  row-gap: 16px;
}

.container {
  border-radius: 24px;
  height: 734px;
  width: 100%;
  overflow: hidden;
}

.content {
  align-items: center;
  column-gap: 80px;
  display: flex;
  justify-content: center;
  padding: 0 64px;
  position: relative;
  top: -734px;
  margin-bottom: -734px;
}

.description {
  margin-bottom: 16px;
}

.image {
  filter: blur(8px);
  height: calc(100% + 8px);
  margin: -4px 0 0 -4px;
  width: calc(100% + 8px);
}

@media only screen and (max-width: 1536px) {
  .title {
    font-size: 64px;
    line-height: 72px;
  }
}

@media only screen and (max-width: 1280px) {
  .title {
    font-size: 56px;
    line-height: 64px;
  }
}

@media only screen and (max-width: 980px) {
  .container {
    height: 680px;
  }
}

@media only screen and (max-width: 768px) {
  .container {
    height: 624px;
  }
}

@media only screen and (max-width: 640px) {
  .container {
    height: 514px;
  }

  .title {
    font-size: 36px;
    line-height: 42px;
  }
}