.container {
  width: 100%;
}

.segmentContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 16px;
  max-width: 380px;
  width: 100%;
  text-align: center;
  row-gap: 16px;
}

.segmentImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 16px;
}

.segmentsContainer {
  display: flex;
  column-gap: 48px;
  align-items: flex-start;
  justify-content: center;
  width: fit-content;
  flex-direction: row;
  row-gap: 40px;
}

.segmentsOuterContainer {
  display: flex;
  align-items: center;
  justify-content: center;
}

.solidLine {
  border: 0.5px solid var(--color-brightPurple);
  height: 0px;
  width: calc(100% * 2 + 40px);
  z-index: 1;
  position: absolute;
  top: 50%;
  display: block;
}

.timelineTextContainer {
  border: 1.5px solid var(--color-brightPurple);
  border-radius: 50%;
  display: flex;
  height: 72px;
  width: 72px;
  padding: 8px;
  text-align: center;
  align-items: center;
  justify-content: center;
  background-color: var(--color-cardBackground);
  text-transform: uppercase;
  z-index: 2;
  position: relative;
}

.timelineTextOuterContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 12px 0;
  position: relative;
  width: 100%;
}

@media only screen and (max-width: 768px) {
  .segmentContainer {
    max-width: none;
  }

  .segmentsContainer {
    flex-direction: column;
  }

  .solidLine {
    display: none;
  }

  .timelineTextOuterContainer {
    margin: 8px 0;
  }
}
