.premintPreviewAssetContainer {
  margin: 0 36px;
}

.marquee {
  display: flex;
  flex-direction: row;
  position: relative;
  width: 100%;
  height: 480px;
}