.emptyButton {
  margin-top: 32px;
}

.emptyDescription {
  margin-top: 40px;
}

.image {
  max-width: 600px;
  width: 100%;
}
