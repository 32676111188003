.body {
  align-items: center;
  display: flex;
  flex-direction: column;
  max-width: 730px;
}

.button {
  margin-top: 48px;
  width: 310px;
}

.priceContainer {
  align-items: center;
  display: flex;
  flex-grow: 1;
}

.card {
  align-items: center;
  align-self: stretch;
  background-color: var(--color-websiteBackground);
  border-radius: 24px;
  box-shadow: var(--box-shadow-button);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 48px 48px 24px;
  row-gap: 40px;
  width: 100%;
}

.cards {
  align-items: center;
  column-gap: 28px;
  display: grid;
  grid-template-columns: minmax(0, 4fr) minmax(0, 1fr) minmax(0, 4fr);
  margin-top: 48px;
}

.image {
  border-radius: 16px;
  box-shadow: var(--box-shadow-button);
  height: 240px;
  object-fit: cover;
  width: 240px;
}

.inBetween {
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 24px;
}

.inBetweenSection {
  align-items: center;
  display: flex;
  flex-direction: column;
  row-gap: 4px;
}

@media only screen and (max-width: 980px) {
  .body {
    max-width: 100%;
  }
}

@media only screen and (max-width: 768px) {
  .button {
    width: 300px;
  }

  .card {
    padding: 16px;
    row-gap: 12px;
  }

  .cards {
    display: flex;
    flex-direction: column;
    margin-top: 24px;
    row-gap: 24px;
  }

  .image {
    height: 155px;
    width: 155px;
  }

  .inBetween {
    column-gap: 16px;
    flex-direction: row;
  }

  .inBetweenSection {
    column-gap: 4px;
    flex-direction: row;
  }

  .priceContainer {
    height: 80px;
  }
}
