.container {
  background-color: var(--color-cardBackground);
  border-radius: 24px;
  box-shadow: var(--box-shadow-button);
  box-sizing: border-box;
  overflow: hidden;
  padding-top: 12px;
  width: 100%;
}

.image {
  border-radius: 16px;
  box-sizing: border-box;
  padding: 0px 12px;
  width: 100%;
}

.info {
  margin-top: 44px;
  padding: 0px 24px;
}

.labelAndValue {
  display: flex;
  flex-direction: column;
  row-gap: 8px;
}

.priceAndDate {
  column-gap: 32px;
  display: flex;
  margin-top: 20px;
}

.separator {
  background-color: var(--color-tertiary);
  height: 1px;
  margin-top: 20px;
  width: 100%;
}

.title {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}