.image {
  width: 100%;
  height: 320px;
  border-radius: 12px;
}

.label {
  width: 120px;
}

.title {
  width: 200px;
}