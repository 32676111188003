.dateAndTimeInput {
  flex-basis: 50%;
  flex-grow: 1;
}

.dateAndTimeInputs {
  column-gap: 16px;
  display: flex;
  width: 100%;
}

.inputs {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
}

.timezoneSelectContainer {
  align-self: flex-start;
}