.artistStatement {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 36px;
  row-gap: 12px;
}

.assets {
  margin-top: 36px;
}

.container {
  background-color: var(--color-cardBackground);
  border-radius: 24px;
  box-shadow: var(--box-shadow-button);
  padding: 48px;
  position: relative;
}

.divider {
  margin-bottom: 36px;
  margin-top: 36px;
}

.notFound {
  margin-top: 16px;
}

.reportButton {
  padding: 12px;
  position: absolute;
  top: 36px;
  right: 36px;
}

.shadowButtonSocial {
  column-gap: 8px;
  display: flex;
  justify-content: space-between;
  padding: 8px 16px;
}

.socials {
  column-gap: 24px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin-top: 16px;
  row-gap: 24px;
}

.username {
  margin-top: 16px;
}

.table {
  border-collapse: separate;
  border-spacing: 16px;
  margin-top: -4px;
  text-align: left;
}

.tableContainer {
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 32px;
}

@media only screen and (max-width: 1024px) {
  .container {
    padding: 32px;
  }
}
