.body {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  row-gap: 20px;
}

.marqueeContainer, .nftsContainer {
  margin-top: 48px;
}

.seeAllButtonBottom, .seeAllButtonTop {
  display: flex;
  justify-content: center;
}

.seeAllButtonBottom {
  margin-top: 64px;
}

.seeAllButtonTop {
  margin-top: 20px;
}