.artistPill {
  position: absolute;
  z-index: 1;
  top: 28px;
  left: 28px;
}

.image {
  border-radius: 12px;
  height: 100%;
  left: 0px;
  width: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
}

.imageContainer {
  padding: 12px;
}

.container {
  border-radius: 16px;
}

.loadingSpinner {
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
}
